<template>
  <v-card>
    <v-card-title>Items</v-card-title>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-model="searchQuery"
        placeholder="Search"
        outlined
        hide-details
        dense
        clearable
        class="user-search me-3 mb-4"
        @keyup.enter="fetchItems"
        @click:clear="clearSearch"
      >
      </v-text-field>

      <v-spacer></v-spacer>
      <div class="d-flex align-center flex-wrap">
        <v-btn color="primary" class="mb-4 me-3" @click="create">
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>Add New Item</span>
        </v-btn>
      </div>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItem"
        :footer-props="footerProps"
        @page-count="pageCount = $event"
      >
        <!-- description -->
        <template #[`item.description`]="{ item }">
          <div class="d-flex flex-column">
            <router-link
              class="font-weight-semibold text-decoration-none"
              :to="{ name: 'items-edit', params: { id: item.id } }"
            >
              {{ item.description }}
            </router-link>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editItem(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItemConfirm(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <!-- dialog -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title> Are you sure you want to delete this item? </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="dialogDelete = !dialogDelete"> Cancel </v-btn>
            <v-btn color="success" @click="deleteItem"> OK </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiExportVariant,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiCarPickup,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import router from '@/router'
import useUIResolver from '../useUIResolver'
import useItems from '../useItems'

export default {
  setup() {
    const itemData = ref({})
    const searchQuery = ref('')
    const totalItem = ref(0)
    const footerProps = ref({
      itemsPerPageOptions: [15, 50, 100],
    })
    const options = ref({
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 15,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dialogDelete = ref(false)
    const headers = ref([])

    const { itemHeaders, resolveDate } = useUIResolver()
    const { items, getItems } = useItems()

    const create = () => {
      router.push({ name: 'items-create' })
    }

    const fetchItems = () => {
      getItems({ ...options.value, ...{ search: searchQuery.value } }).then(response => {
        totalItem.value = response.data.total
      })
    }

    onMounted(() => {
      headers.value = itemHeaders()

      fetchItems()
    })

    watch(options, fetchItems)

    const clearSearch = () => {
      searchQuery.value = null
    }

    const editItem = item => {
      router.push({ name: 'items-edit', params: { id: item.id } })
    }

    const deleteItem = () => {
      axios.delete(`/items/${itemData.value.id}`).then(() => {
        fetchItems()
        dialogDelete.value = false
      })
    }

    const deleteItemConfirm = item => {
      itemData.value = item
      dialogDelete.value = true
    }

    return {
      dialogDelete,
      itemData,
      headers,
      searchQuery,
      totalItem,
      footerProps,
      options,
      resolveDate,
      create,

      // functions
      clearSearch,

      items,
      fetchItems,
      getItems,

      editItem,
      deleteItem,
      deleteItemConfirm,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiExportVariant,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiCarPickup,
      },
    }
  },
}
</script>
